import Immutable from 'immutable';
import mergeReducers from 'redux/utils/mergeReducers';
import createAddressPickerReducer, {
  getInitialState as getAddressPickerInitialState,
} from 'redux/modules/AddressPickerNew/reducer';
import * as types from './types';
import {
  ADDRESS_PICKER_PREFIX_BILLING,
  ADDRESS_PICKER_PREFIX_PREVIOUS,
  ABOUT_YOU_SECTION,
  REFERRAL_AND_LEGAL_SECTION,
  DIRECT_DEBIT_SECTION,
  ADDRESS_PICKER_KEY_BILLING,
  ADDRESS_PICKER_KEY_PREVIOUS,
  FORM_ERRORS,
  FORM_SECTIONS,
  REQUEST_STATUS,
  ONE_TOUCH_SWITCH_SECTION,
} from './constants';
import {
  aboutYouSection,
  referralAndLegalSection,
  directDebitSection,
  oneTouchSwitchSection,
  HAS_EXISTING_LINE,
  PROVIDER_NOT_LISTED,
  FIND_STATUS,
  CURRENT_PROVIDER,
  MATCH_ID,
  SWITCHING_REFERENCE,
  BROADBAND_REFERENCE,
} from './form';

const getInitialFormState = (fields) => {
  const fieldsArray = Object.values(fields);
  const reducedState = fieldsArray.reduce(
    (state, fieldKey) => {
      state.fieldPristine[fieldKey] = true;
      state.fieldErrors.push({ field: fieldKey, valid: false });
      state.values[fieldKey] = '';

      return state;
    },
    {
      fieldPristine: {},
      fieldErrors: [],
      values: {},
    }
  );

  return {
    ...reducedState,
    valid: false,
  };
};

const yourAddressBillingInitialState = getAddressPickerInitialState(
  ADDRESS_PICKER_KEY_BILLING
);
const yourAddressPreviousInitialState = getAddressPickerInitialState(
  ADDRESS_PICKER_KEY_PREVIOUS
);

const billingAddressReducer = createAddressPickerReducer(
  ADDRESS_PICKER_PREFIX_BILLING,
  ADDRESS_PICKER_KEY_BILLING
);
const previousAddressReducer = createAddressPickerReducer(
  ADDRESS_PICKER_PREFIX_PREVIOUS,
  ADDRESS_PICKER_KEY_PREVIOUS
);

const aboutYouInitialState = getInitialFormState(aboutYouSection);
const directDebitSectionInitialState = getInitialFormState(directDebitSection);
const oneTouchSwitchInitialState = getInitialFormState(oneTouchSwitchSection);
const referralAndLegalInitialState = getInitialFormState(
  referralAndLegalSection
);

const initialState = Immutable.fromJS({
  enableScroll: false,
  activeStep: 1,
  marketing: null,
  ...yourAddressBillingInitialState,
  ...yourAddressPreviousInitialState,
  forms: {
    [ABOUT_YOU_SECTION]: {
      ...aboutYouInitialState,
      values: {
        ...aboutYouInitialState.values,
        [aboutYouSection.ABOUT_YOU_RECENT_MOVE]: false,
        [aboutYouSection.ABOUT_YOU_ADDITIONAL_HOLDER_SELECT]: false,
        [aboutYouSection.ABOUT_YOU_CBC_ADDITIONAL_SELECT]: false,
        [aboutYouSection.ABOUT_YOU_PAPER_BILLING_CONFIRM]: false,
      },

      paperBillingModal: {
        open: false,
        variant: null,
      },
      salesEeccContractLoading: false,
    },

    [DIRECT_DEBIT_SECTION]: {
      ...directDebitSectionInitialState,
      values: {
        ...directDebitSectionInitialState.values,
        [directDebitSection.BILLING_ADDRESS_SAME]: true,
      },
    },

    [ONE_TOUCH_SWITCH_SECTION]: {
      ...oneTouchSwitchInitialState,
      values: {
        ...oneTouchSwitchInitialState.values,
        [oneTouchSwitchSection.HAS_EXISTING_LINE]: true,
        [oneTouchSwitchSection.PROVIDER_NOT_LISTED]: false,
      },
    },

    [REFERRAL_AND_LEGAL_SECTION]: {
      ...referralAndLegalInitialState,
      values: {
        ...referralAndLegalInitialState.values,
        [referralAndLegalSection.IS_REFERRAL]: false,
        [referralAndLegalSection.TERMS_AND_CONDITIONS]: false,
      },
    },
  },
  [FORM_ERRORS]: null,
  scrollToError: false,
  shouldCreditCheck: false,
  contractGenerationStatus: REQUEST_STATUS.IDLE,
});

function YourDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case types.DETAILS_FORM_SET_LOADING:
      return state.setIn(['forms', action.form, 'submitting'], action.loading);

    case types.DETAILS_FORM_SET_PRISTINE: {
      const fieldPristine = state
        .getIn(['forms', action.form, 'fieldPristine'])
        .map(() => action.pristine);

      return state.setIn(
        ['forms', action.form, 'fieldPristine'],
        fieldPristine
      );
    }
    case types.SET_OPT_OUT_MARKETING:
      return state.merge({
        marketing: action.checked,
      });

    case types.DETAILS_FORM_SUBMIT: {
      return state.set(FORM_ERRORS, null);
    }

    case types.DETAILS_FORM_UPDATE: {
      const { field, form, value } = action;
      return state
        .setIn(['forms', form, 'values', field], value)
        .setIn(['forms', form, 'fieldPristine', field], false);
    }

    case types.CLEAR_OTS_FORM: {
      return state
        .setIn(
          ['forms', ONE_TOUCH_SWITCH_SECTION, 'values', CURRENT_PROVIDER],
          null
        )
        .setIn(
          ['forms', ONE_TOUCH_SWITCH_SECTION, 'values', FIND_STATUS],
          'NOT_STARTED'
        )
        .setIn(
          ['forms', ONE_TOUCH_SWITCH_SECTION, 'values', HAS_EXISTING_LINE],
          true
        )
        .setIn(
          ['forms', ONE_TOUCH_SWITCH_SECTION, 'values', PROVIDER_NOT_LISTED],
          false
        )
        .setIn(['forms', ONE_TOUCH_SWITCH_SECTION, 'values', MATCH_ID], null)
        .setIn(
          ['forms', ONE_TOUCH_SWITCH_SECTION, 'values', SWITCHING_REFERENCE],
          null
        )
        .setIn(
          ['forms', ONE_TOUCH_SWITCH_SECTION, 'values', BROADBAND_REFERENCE],
          ''
        );
    }

    case types.DETAILS_FORM_FORM_SET_VALIDATION: {
      const { fields, form, valid } = action;

      return state
        .setIn(['forms', form, 'fieldErrors'], Immutable.List(fields))
        .setIn(['forms', form, 'valid'], valid);
    }

    case types.UPDATE_DIRECT_DEBIT_NAME: {
      const { value } = action;

      return state.setIn(
        [
          'forms',
          DIRECT_DEBIT_SECTION,
          'values',
          directDebitSection.DIRECT_DEBIT_NAME,
        ],
        value
      );
    }

    case types.SET_FORM_ERRORS: {
      const { errors } = action;

      const formattedErrors = errors ? Immutable.List(errors) : null;

      return state.set(FORM_ERRORS, formattedErrors);
    }

    case types.TOUCH_DETAILS_FORM: {
      let newState = state;

      FORM_SECTIONS.forEach((form) => {
        const fieldsPristine = state
          .getIn(['forms', form, 'fieldPristine'])
          .map(() => false);

        newState = newState.setIn(
          ['forms', form, 'fieldPristine'],
          fieldsPristine
        );
      });

      return newState;
    }

    case types.SET_SCROLL_TO_ERROR: {
      const { shouldScroll } = action;

      return state.set('scrollToError', shouldScroll);
    }

    case types.SET_PAPER_BILLING_MODAL: {
      const { open, variant } = action;

      return state.mergeDeep({
        forms: {
          [ABOUT_YOU_SECTION]: {
            paperBillingModal: {
              open: open,
              variant: variant,
            },
          },
        },
      });
    }

    case types.SET_CREDIT_CHECK: {
      const { shouldCreditCheck } = action;

      return state.merge({
        shouldCreditCheck,
      });
    }

    case types.DETAILS_REQUEST_EECC_CONTRACT:
      return state.setIn(
        ['forms', ABOUT_YOU_SECTION, 'salesEeccContractLoading'],
        true
      );
    case types.DETAILS_REQUEST_EECC_CONTRACT_SUCCESS:
      return state.setIn(
        ['forms', ABOUT_YOU_SECTION, 'salesEeccContractLoading'],
        false
      );
    case types.DETAILS_REQUEST_EECC_CONTRACT_FAILURE:
      return state.setIn(
        ['forms', ABOUT_YOU_SECTION, 'salesEeccContractLoading'],
        false
      );

    case types.CONTRACT_GENERATION_LOADING:
      return state.set('contractGenerationStatus', REQUEST_STATUS.LOADING);
    case types.CONTRACT_GENERATION_SUCCESS:
      return state.set('contractGenerationStatus', REQUEST_STATUS.SUCCESS);
    case types.CONTRACT_GENERATION_FAILURE:
      return state.set('contractGenerationStatus', REQUEST_STATUS.ERROR);

    case types.SET_BILLING_ADDRESS: {
      const { addressId } = action;
      return state.setIn(
        [
          'forms',
          DIRECT_DEBIT_SECTION,
          'values',
          directDebitSection.BILLING_ADDRESS,
        ],
        addressId
      );
    }

    case types.SET_PREVIOUS_ADDRESS: {
      const { addressId } = action;

      return state.setIn(
        [
          'forms',
          ABOUT_YOU_SECTION,
          'values',
          aboutYouSection.ABOUT_YOU_PREVIOUS_ADDRESS,
        ],
        addressId
      );
    }

    default:
      return state;
  }
}

export default mergeReducers(
  initialState,
  YourDetailsReducer,
  billingAddressReducer,
  previousAddressReducer
);
