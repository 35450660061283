import { call, put, select } from 'redux-saga/effects';

import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { translateBackendErrorToMessage } from 'redux/modules/Shared/BackendParser';

import {
  setOneTouchSwitchFormValidation,
  updateFormOneTouchSwitch,
  updateReferralAndLegalForm,
  sectionSubmissionFailed,
  setFormPristine,
} from '../actions';
import { updateHomephoneOption } from '../../Broadband/actions';
import { getOneTouchSwitchFormFields } from '../api';
import { validateOneTouchSwitch } from '../validation/oneTouchSwitch';
import { OrderSummaryApi } from '../../OrderSummary/api';
import { ONE_TOUCH_SWITCH_SECTION } from '../constants';
import { postOneTouchSwitch } from '../services';
import {
  BROADBAND_REFERENCE,
  CURRENT_PROVIDER,
  HAS_EXISTING_LINE,
  MATCH_ID,
  PROVIDER_NOT_LISTED,
  SWITCHING_REFERENCE,
} from '../form';
import EligibilityServiceApi from '../../Eligibility/api';

function* formValidate() {
  const isEligible = yield select(
    EligibilityServiceApi.isEligibleForOneTouchSwitch
  );
  const hasBroadband = yield select(OrderSummaryApi.getHasBroadband);
  const isTBYB = yield select(OrderSummaryApi.getIsTBYBOfferSelected);

  if (isEligible && hasBroadband && !isTBYB) {
    const fields = yield select(getOneTouchSwitchFormFields);
    const validation = validateOneTouchSwitch(fields);
    yield put(
      setOneTouchSwitchFormValidation(validation.valid, validation.fields)
    );
  } else {
    yield put(setOneTouchSwitchFormValidation(true, []));
  }
}

function* updateTwoFormsSaga(action) {
  const { firstFormToUpdate, secondFormToUpdate } = action.payload;

  try {
    for (const update of firstFormToUpdate) {
      yield put(updateFormOneTouchSwitch(update.field, update.value));
    }

    for (const update of secondFormToUpdate) {
      yield put(updateReferralAndLegalForm(update.field, update.value));
    }
  } catch (error) {
    return error;
  }
}

function* updateLandlineNumberSaga(action) {
  const { newLandLine } = action.payload;
  try {
    const landlineContextUpdate = 'update_landline_number';
    yield put(
      updateHomephoneOption(
        'landlineNumber',
        newLandLine,
        landlineContextUpdate
      )
    );
  } catch (error) {
    return error;
  }
}

function* formSubmit() {
  const isEligible = yield select(
    EligibilityServiceApi.isEligibleForOneTouchSwitch
  );
  const hasBroadband = yield select(OrderSummaryApi.getHasBroadband);
  const isTBYB = yield select(OrderSummaryApi.getIsTBYBOfferSelected);
  const fields = yield select(getOneTouchSwitchFormFields);

  if (
    isEligible &&
    hasBroadband &&
    fields[HAS_EXISTING_LINE] &&
    !fields[PROVIDER_NOT_LISTED] &&
    fields[CURRENT_PROVIDER] &&
    !isTBYB
  ) {
    yield put(setFormPristine(ONE_TOUCH_SWITCH_SECTION, false));

    try {
      yield call(postOneTouchSwitch, {
        providerNotListed: fields[PROVIDER_NOT_LISTED],
        provider: fields[CURRENT_PROVIDER].id,
        correlationId: fields[MATCH_ID],
        switchingReference: fields[SWITCHING_REFERENCE],
        losingProviderAccountNumber: fields[BROADBAND_REFERENCE],
      });
    } catch (error) {
      const message = translateBackendErrorToMessage(error);
      Sentry.log(error, ERRORS.UPDATE_YOUR_BROADBAND_SWITCH);
      return yield put(sectionSubmissionFailed(message));
    }
  }
}

export {
  formValidate,
  updateTwoFormsSaga,
  formSubmit,
  updateLandlineNumberSaga,
};
