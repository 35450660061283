import { YourDetails } from 'app/components/modules/YourDetails';
import applicationCheck from 'redux/modules/ApplicationCheck';
import {
  deleteFormError,
  getFormInitialData,
  validateDetailsForm,
  setScrollToError,
  checkPaperBilling,
  clearOneTouchSwitchForm,
} from 'redux/modules/YourDetails/actions';
import {
  getFormErrors,
  getWholeFormValidationErrors,
  getFormsSubmitting,
  getFormsValid,
  getScrollToError,
  getOneTouchSwitchFindStatus,
} from 'redux/modules/YourDetails/api';
import { STORE_NAME } from 'redux/modules/YourDetails/constants';
import { connector } from 'redux/utils/connector';
import { setSubmitDeniedDialog } from '../../OrderSummary/actions';
import { OrderSummaryApi } from '../../OrderSummary/api';
import EligibilityServiceApi from '../../Eligibility/api';

const YourDetailsContainerUnwrapped = connector(
  STORE_NAME,
  (state) => ({
    formErrors: getFormErrors(state),
    isLoading: getFormsSubmitting(state),
    isFormValid: getFormsValid(state),
    isDeniedDialogOpen: OrderSummaryApi.getSubmitDialogOpen(state),
    validationErrors: getWholeFormValidationErrors(state),
    shouldScroll: getScrollToError(state),
    isOneTouchSwitchComplete: getOneTouchSwitchFindStatus(state),
    isOneTouchSwitchEligible: EligibilityServiceApi.isEligibleForOneTouchSwitch(
      state
    ),
    hasBroadband: OrderSummaryApi.getHasBroadband(state),
  }),
  {
    onSubmit: checkPaperBilling,
    onErrorSnackClose: deleteFormError,
    handleDeniedDialogClose: () => setSubmitDeniedDialog(false),
    getFormInitialData,
    validateForm: validateDetailsForm,
    onScrollEnd: () => setScrollToError(false),
    clearOTSForm: clearOneTouchSwitchForm,
  }
)(YourDetails);

const YourDetailsContainer = applicationCheck(YourDetailsContainerUnwrapped);

export { YourDetailsContainer };
