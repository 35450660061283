import {
  call,
  takeLatest,
  fork,
  put,
  select,
  takeEvery,
  all,
} from 'redux-saga/effects';
import { flattenDeep } from 'lodash';

import constructSagas from 'redux/modules/AddressPickerNew/saga';
import { getActionName } from 'redux/modules/AddressPickerNew/utils';
import { GET_ADDRESS_LIST_SUCCESS } from 'redux/modules/AddressPickerNew/types';
import {
  confirmYourDetailsForm,
  yourDetailsSubmissionStarted,
  yourDetailsSubmissionEnded,
} from 'app/redux/modules/OrderSummary/actions';

import {
  contractGenerationFailure,
  contractGenerationLoading,
  contractGenerationSuccess,
  sectionSubmissionFailed,
  setScrollToError,
  setBillingAddress,
  setPreviousAddress,
} from '../actions';
import { getFormsValid } from '../api';
import * as types from '../types';
import {
  ADDRESS_PICKER_PREFIX_BILLING,
  ADDRESS_PICKER_PREFIX_PREVIOUS,
  ABOUT_YOU_SECTION,
  REFERRAL_AND_LEGAL_SECTION,
  DIRECT_DEBIT_SECTION,
  INVALID_FORM_ERROR_MESSAGE,
  ONE_TOUCH_SWITCH_SECTION,
} from '../constants';
import {
  checkPaperBilling,
  formSubmit as formSubmitAboutYou,
  formValidate as formValidateAboutYou,
  getShouldCreditCheck,
  generateContract,
  postContract,
} from './aboutYou';
import {
  addFormError,
  deleteFormError,
  handleApiFieldsErrors,
  validateAndTouchForm,
  validateForm,
} from './formErrorHandling';
import { handleSetOptOutMarketing } from './consolidation_legacy';
import {
  formSubmit as formSubmitDirectDebitSection,
  formValidate as formValidateDirectDebitSection,
} from './directDebitSection';
import {
  formSubmit as formSubmitReferralAndLegal,
  formValidate as formValidateReferralAndLegal,
  handleEeccTermsClicked,
  handleTermsAndConditionsUpdate,
} from './referralAndLegal';

import {
  formSubmit as formSubmitOneTouchSwitch,
  formValidate as formValidateOneTouchSelection,
  updateTwoFormsSaga,
  updateLandlineNumberSaga,
} from './oneTouchSwitch';
function* validateFormSection({ form }) {
  // debounce to prevent validation calls on every user input, per form.
  //
  // TODO: figure out how to better handle validation in
  // app/components/modules/YourDetails/OneTouchSwitchSection/index.js
  // to avoid multiple forms racing against each other, as this
  // causes all but the last validation call to be dropped by the
  // `delay(n)` call here
  // yield delay(120);
  switch (form) {
    case ABOUT_YOU_SECTION:
      yield call(formValidateAboutYou);
      break;
    case DIRECT_DEBIT_SECTION:
      yield call(formValidateDirectDebitSection);
      break;
    case REFERRAL_AND_LEGAL_SECTION:
      yield call(formValidateReferralAndLegal);
      break;
    case ONE_TOUCH_SWITCH_SECTION:
      yield call(formValidateOneTouchSelection);
      break;
    default:
      return;
  }
}

function* handleInitialFormRequests() {
  try {
    yield put(contractGenerationLoading());
    yield all([call(getShouldCreditCheck), call(generateContract)]);
    yield put(contractGenerationSuccess());
  } catch (error) {
    yield put(contractGenerationFailure());
  }
}

const getHasSubmissionError = (submissionResult) =>
  Boolean(
    flattenDeep(submissionResult).find(
      (result) => result?.type === types.FORM_SECTION_SUBMISSION_FAILED
    )
  );

function* handleAddressFetchSuccess({ type, addresses }) {
  const addressId = addresses?.length > 0 && addresses[0].uuid;

  if (!addressId) {
    return;
  }

  if (type.includes(ADDRESS_PICKER_PREFIX_BILLING)) {
    yield put(setBillingAddress(addressId));
    yield call(formValidateDirectDebitSection);
  } else {
    yield put(setPreviousAddress(addressId));
    yield call(formValidateAboutYou);
  }
}

function* submitForm() {
  yield put(yourDetailsSubmissionStarted());
  yield call(validateAndTouchForm);

  const isFormValid = yield select(getFormsValid);
  if (!isFormValid) {
    yield put(setScrollToError(true));
    yield put(sectionSubmissionFailed(INVALID_FORM_ERROR_MESSAGE));
    return yield put(yourDetailsSubmissionEnded());
  }

  const submissionResult = [];

  const resultAboutYou = yield call(formSubmitAboutYou);
  const resultDirectDebitSection = yield call(formSubmitDirectDebitSection);
  const resultReferralAndLegal = yield call(formSubmitReferralAndLegal);
  const resultOneTouchSwitch = yield call(formSubmitOneTouchSwitch);

  submissionResult.push([
    resultAboutYou,
    resultDirectDebitSection,
    resultReferralAndLegal,
    resultOneTouchSwitch,
  ]);

  const hasError = getHasSubmissionError(submissionResult);

  if (hasError) {
    return yield put(yourDetailsSubmissionEnded());
  }

  yield put(confirmYourDetailsForm());
}

function* combinedSagas() {
  yield takeLatest(types.CHECK_PAPER_BILLING, checkPaperBilling);
  yield takeLatest(types.DETAILS_FORM_SUBMIT, submitForm);
  yield takeLatest(types.DETAILS_FORM_UPDATE, validateFormSection);
  yield takeLatest(types.DETAILS_FORM_VALIDATE, validateForm);
  yield takeLatest(types.VALIDATE_BILLING_ADDRESS, validateFormSection);

  // Listen for successful address list retrieval actions. `handleAddressFetchSuccess` is triggered
  // to update the form state with the first retrieved address ID to match AddressPicker state and initiate form validation
  yield takeLatest(
    getActionName(GET_ADDRESS_LIST_SUCCESS, ADDRESS_PICKER_PREFIX_BILLING),
    handleAddressFetchSuccess
  );
  yield takeLatest(
    getActionName(GET_ADDRESS_LIST_SUCCESS, ADDRESS_PICKER_PREFIX_PREVIOUS),
    handleAddressFetchSuccess
  );

  yield takeLatest(
    types.TERMS_AND_CONDITIONS_UPDATE,
    handleTermsAndConditionsUpdate
  );
  yield takeLatest(types.UPDATE_BOTH_FORMS, updateTwoFormsSaga);
  yield takeLatest(types.UPDATE_LANDLINE_NUMBER, updateLandlineNumberSaga);

  yield takeEvery(types.FORM_SECTION_SUBMISSION_FAILED, addFormError);
  yield takeEvery(types.FORM_SECTION_SUBMISSION_FAILED, handleApiFieldsErrors);
  yield takeEvery(types.DELETE_FORM_ERROR, deleteFormError);

  yield takeEvery(types.GET_FORM_INITIAL_DATA, handleInitialFormRequests);
  yield takeEvery(types.DETAILS_REQUEST_EECC_CONTRACT, postContract);

  yield takeLatest(types.SET_OPT_OUT_MARKETING, handleSetOptOutMarketing);
  yield takeLatest(types.EECC_CONTRACT_LINK_CLICKED, handleEeccTermsClicked);

  const addressPickerBillingSagas = constructSagas(
    ADDRESS_PICKER_PREFIX_BILLING
  );
  const addressPickerPreviousSagas = constructSagas(
    ADDRESS_PICKER_PREFIX_PREVIOUS
  );
  yield fork(addressPickerBillingSagas);
  yield fork(addressPickerPreviousSagas);
}

export { combinedSagas, validateFormSection, submitForm };
